
import tasqSignalsModule from '@/store/modules/tasqSignalsModule';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class ChartTimeRangeComponent extends Vue {
  @Prop({ type: Array, required: false, default: () => [] }) chartTimes?: any;
  @Prop({ type: Number, required: false, default: '' }) signalChartTime?: any;
  @Prop({ type: Boolean, required: false, default: false }) hideChartTimes?: any;

  changeChartTimes(val) {
    this.$emit('change-chart-times',val)
  }

  get isAllData(){
     return tasqSignalsModule.isAllData
  }
}
